.bulk {
    width: 100%;
    display: flex;
    flex-direction: wor;
    justify-content: space-around;
}
.bulk .container {
    box-sizing: border-box;
    width: 90%;
    height: 80%;
    background: var(--menu-bg);
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 0.5rem 2rem #00000033;
    margin-left: var(--navbar-width);
    margin-top: 5%;
}

.row {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}
.bulk .option {
    margin: 1rem 0rem 1rem 1rem;
    width: calc(100% - 4rem);
    padding: 0rem 1rem 0rem 1rem;
    background: var(--container-bg);
    border-radius: 0.5rem;
    float: left;
}
.bulk .option label {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    clear: both;
    color: var(--text-color);
    padding-left: 0.2rem;
}
.bulk .option input[type="text"] {
    box-sizing: border-box;
    width: 7rem;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    border: 1px solid var(--input-border-color);
    background: var(--input-bg);
    padding: 0.3rem;
    border-radius: 0.5rem;
    color: var(--text-color);
}

.bulk .option input[type="text"].long {
    width: 12rem;
}

.bulk .option input[type="button"] {
    box-sizing: border-box;
    padding: 0.2rem 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 0.5rem;
    color: #fff;
    background: var(--primary-color);
    height: 1.9rem;
    margin-top: 1rem;
    font-size: 14px;
}

.bulk .tabulator {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    font-size: 13px;
    border-radius: 0.5rem;
    background: var(--container-bg);
}
.bulk .tabulator-header {
    border: none;
}

.bulk .tabulator-row {
    border-bottom: 1px solid #00000011;
    background: var(--container-bg);
    color: var(--text-color);
}
.bulk .tabulator-row.tabulator-selectable:hover {
    background-color: var(--inset-bg) !important;
}
.bulk .tabulator-headers .tabulator-col {
    background: var(--container-bg);
    color: var(--text-color);
}

.bulk .tabulator .tabulator-cell.tabulator-editing {
    border: 2px solid var(--primary-color);
    outline: none;
}
.bulk .tabulator .tabulator-cell.tabulator-editing input {
    outline: none;
    border: 0px solid transparent;
}
.bulk .tabulator-cell[tabulator-field="fallback"],
.bulk .tabulator-cell[tabulator-field="preview"] {
    padding: 0.5rem;
    transition: padding 0.1s;
    text-align: center;
}

.bulk .video-preview,
.bulk .fallback-preview {
    object-fit: contain;
    max-height: 1.2rem;
    transition: max-height 0.1s;
    margin-top: 0.1rem;
}
.bulk .tabulator-cell[tabulator-field="preview"]:hover,
.bulk .tabulator-cell[tabulator-field="fallback"]:hover {
    padding: 0px;
}
.bulk .tabulator-cell[tabulator-field="preview"]:hover .video-preview,
.bulk .tabulator-cell[tabulator-field="fallback"]:hover .fallback-preview {
    max-height: 100%;
    margin-top: 0px;
}

.bulk .tabulator-cell {
    line-height: 24px;
}

.bulk .tabulator-cell svg {
    float: left;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
}

.bulk .tabulator-cell[tabulator-field="clickurl"] svg,
.bulk .tabulator-cell[tabulator-field="imptracker"] svg {
    margin-top: 0.3rem;
}

.bulk .tabulator-cell[tabulator-field="clickurl"] input,
.bulk .tabulator-cell[tabulator-field="imptracker"] input {
    color: var(--text-color);
}

.tabulator-edit-list {
    position: absolute;
    background: #fff;
    border: 1px solid #00000022;
    z-index: 100;
    box-shadow: 0 0.2rem 0.5rem #00000033;
}
.tabulator-edit-list-item {
    border-bottom: 1px solid #00000022;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 14px;
}
.tabulator-edit-list-item:hover {
    background: var(--primary-color);
    color: #fff;
}
.tabulator-edit-list-item:last-child {
    border-bottom: none;
}

.bulk .row.end {
    justify-content: end;
}

.bulk .continue {
    margin-right: 1rem;
    background: var(--primary-color);
    color: #fff;
    margin-bottom: 2rem;
}

.bulk .continue.disabled {
    pointer-events: none;
    background: #00000011;
    color: #00000066;
}
