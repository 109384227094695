:root {
    --navbar-width: 60px;
}

.navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    background: var(--menu-bg);
    width: var(--navbar-width);
    box-shadow: 0 0rem 1rem #00000033;
    z-index: 110;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: slideIn 0.3s ease-in-out;
}

.navbar ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar a:link {
    text-decoration: none;
    color: var(--primary-color);
    fill: var(--primary-color);
}

.navbar li {
    color: var(--primary-color);
    fill: var(--primary-color) !important;
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    height: 60px;
    line-height: 60px;

    cursor: pointer;
}
.navbar ul.top li {
    border-bottom: 1px solid #00000011;
}
.navbar ul.bottom li {
    border-top: 1px solid #00000011;
}
.navbar li:hover {
    background: var(--primary-color);
    color: #fff;
}

.navbar li.logout {
    margin-left: -0.3rem;
}

.navbar ul li svg {
    margin: 0px;
}

.navbar-logo svg {
    height: 35px;
}
.navbar li.navbar-logo {
    pointer-events: none;
    padding-top: 0.5rem;
    height: 60px;
}
.navbar li.navbar-logo:hover {
    background: var(--menu-bg);
}

.navbar li.alert {
    background: rgb(235, 100, 69);
    color: #fff;
}

.popup.warning {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 600px;
    transform: translateX(-50%);
    z-index: 1000;
    background: var(--menu-bg);
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 0rem 0 999rem #00000044, 0 0rem 1rem #00000033;
}
.popup.warning .button-row {
    text-align: right;
}
.popup.warning .button {
    margin-top: 1rem;
    background: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    width: auto;
    display: inline-block;
}

.navbar li.active {
    border-right: 3px solid var(--primary-color);
}

@keyframes slideIn {
    from {
        transform: translateX(calc(0px - var(--navbar-width)));
    }
    to {
        transform: translateX(0px);
    }
}
