:root {
    --advertisers-width: 300px;
    --top-height: 4rem;
    --toolbox-top-margin: 4rem;
    --toolbox-height: 2rem;
    --title-margin-top: 5rem;
    --title-height: 2rem;
    --top-half-height: calc(
        var(--top-height) + var(--toolbox-top-margin) + var(--toolbox-height) + var(--title-margin-top) +
            var(--title-height)
    );
    --item-list-height: calc(100% - var(--top-half-height));
}

.manage {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100vh;
}
.manage .top {
    box-sizing: border-box;
    height: var(--top-height);
}

.manage .back {
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    top: 1rem;
    left: 1rem;
    font-size: 16px;
    text-transform: uppercase;
    background-color: #fff;
    color: var(--primary-color);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    text-align: center;
}
.manage .advertisers,
.manage .campaigns,
.manage .creativeset {
    box-sizing: border-box;
    border-radius: 0.5rem;
    position: relative;
    display: block;
    height: 90%;
    margin-top: 2.5%;
    width: 80%;
    max-width: calc(100vw - var(--navbar-width) - 2rem);
    min-width: 600px;
    min-height: 80%;
    background-color: var(--menu-bg);
    box-shadow: 0 0.5rem 2rem #00000033;
    margin-left: var(--navbar-width);
    overflow: hidden;
    resize: none;
    color: var(--text-color);
}

::-webkit-scrollbar {
    width: 15px;
}
::-webkit-scrollbar-track {
    background: var(--menu-bg);
    padding: 0px;
    margin: 0px;
}
::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
    border: 4px solid var(--menu-bg);
    border-radius: 10px;
}
::-webkit-resizer {
    display: block;
    position: absolute;
    background: var(--menu-bg);
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    border: 1px solid blue;
    border-bottom-right-radius: 0.5rem;
    top: -1px;
}

.manage h2 {
    font-family: "Noto Sans";
    font-weight: 300;
    width: 100%;
    height: 3rem;
    text-align: center;
    color: #fff;
    margin: 0px 0px 1rem 0px;
    padding: 0.5rem 0px;
    background: var(--primary-color);
    margin: 0px;
}

.manage h2 svg {
    position: relative;
    top: 0.2rem;
    margin-right: 1rem;
    height: 1.5rem;
}

.manage h3 {
    font-weight: 300;
    border-bottom: 1px solid #00000011;
    margin: 0px;
    margin-bottom: 1rem;
    padding: 1rem;
    cursor: pointer;
    transition: background 0.2s;
    font-size: 18px;
}
.manage h3:hover {
    /* background: var(--primary-light-color); */
    border-bottom: 1px solid var(--primary-color);
}

.manage p.title {
    margin-left: 1rem;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
}
.manage input[type="text"] {
    border: none;
    border: 1px solid var(--input-border-color);
    border-radius: 0.5rem;
    background: var(--input-bg);
    font-size: 16px;
    color: var(--text-color);
    width: calc(100% - 2rem);
    padding: 0.5rem;
    outline: none;
    margin-left: 1rem;
    width: calc(100% - 3rem);
    margin-top: 1rem;
}
.manage input[type="text"]:focus {
    background: var(--input-focus-bg);
    caret-color: var(--text-color);
}

.manage .toolbox ~ .list-header {
    margin-top: 1rem;
}
.manage .list-header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0.5rem 0;
    margin: 0;
    margin-top: var(--title-margin-top);
    /* border-bottom: 1px solid #00000011; */
    font-weight: 500;
    height: var(--title-height);
}

.manage .list-header span:first-child {
    margin-left: 3.5rem;
    display: block;
}

.manage .creativeset .list-header {
    font-weight: 300;
    font-size: 18px;
    display: block;
}

.list-header .button {
    background: var(--primary-color);
    color: #fff;
    font-size: 12px;
}

.manage .advertisers ul,
.manage .creativeset ul {
    width: 100%;
    padding: 0;
    overflow-y: scroll;
    margin-top: 0px;
    /* height: calc(80% - 2rem); */
    height: var(--item-list-height);
}

.manage .advertisers li .item,
.manage .campaigns li .item,
.manage .creativeset li .item {
    color: var(--text-color);
    box-sizing: border-box;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid var(--item-border-color);
    display: flex;
}

.manage .item {
    cursor: pointer;
}

.manage .item:hover {
    background: var(--primary-color);
    color: var(--primary-item-color);
}

.manage .advertisers li.active {
    background: var(--primary-color);
    color: #fff;
}

.manage .campaigns .light {
    font-weight: 200;
}

.manage .campaigns ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.manage ul {
    animation: manageFadeIn 0.3s ease-in;
    animation-fill-mode: both;
}

.manage .advertisers li {
    padding: 0px;
}

.manage .campaigns .item,
.manage .creativeset .item {
    box-sizing: border-box;
    display: flex;
    color: #666;
    border-bottom: 1px solid var(--item-border-color);
    padding: 0rem 1rem;
    width: 100%;
    cursor: pointer;
    font-weight: 400;
}

.manage .item svg {
    position: relative;
    top: 0.2rem;
    color: var(--text-color);
}

.manage .item .checked svg {
    color: var(--primary-color);
}
.manage .item .clickable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;
}
.manage .campaigns .item:hover,
.manage .creativeset .item:hover {
    background: var(--primary-color);
    color: #fff;
}
.manage .campaigns li.selected {
    background: #00000011;
}

.manage .item:hover svg {
    color: #fff;
}
.manage .item-check {
    font-size: 18px;
    position: relative;
    top: 0.3rem;
}
.manage .tag {
    box-sizing: border-box;
    width: 100%;
    margin: 2rem auto 1rem auto;
    max-height: 100%;
    overflow: hidden;
    /* box-shadow: 0 0.5rem 1rem #00000022; */
    transition: max-height 0.5s;
    min-height: 400px;
}

.manage .tag.collapsed {
    max-height: 55px;
    border-bottom: 1px solid #00000011;
}

.manage .tag.collapsed:hover {
    border-bottom: 1px solid var(--primary-color);
}
.manage .cm-editor {
    background: #f0f0f0;
    width: calc(100% - 2rem);
    border-radius: 0.5rem;
    /* border: 1px solid #00000022; */
    font-size: 12px;
    padding: 0.5rem;
    margin: 0 1rem 0 1rem;
    max-height: 350px;
}

.manage .row {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}
.manage .row.end {
    justify-content: end;
}
.manage .row.start {
    justify-content: start;
}
.manage .row.center {
    justify-content: center;
}
.manage .row.space-between {
    justify-content: space-between;
}
.manage .select-tag {
    margin-bottom: 1rem;
    margin-left: 1rem;
}

a.download-tag {
    text-decoration: none;
}

.manage .copy-tag,
.manage .download-tag {
    margin: 1rem 1rem 1rem 0;
    padding: 0.5rem;
    background: var(--primary-color);
    color: #fff;
    width: 90px;
    transition: background 0.5s;
}
.manage .download-tag {
    width: auto;
}
.manage .copy-tag:hover,
.manage .download-tag:hover,
.manage.download-tag:hover {
    background-color: var(--primary-light-color);
}

.manage .copy-tag.highlight {
    background: #14a03c;
}
.manage .copy-tag.highlight:hover {
    background-color: #14a03c;
}

.tag-item {
    color: var(--text-color) !important;
}

.tag-item-selected {
    background: var(--primary-color) !important;
}

.tag-item-focused {
    background: var(--primary-light-color) !important;
    color: #fff !important;
}

.manage .content {
    overflow-y: scroll;
    height: calc(100% - 3.5rem);
}

.manage .menu {
    position: absolute;
    display: block;
    right: 1rem;
    top: 1rem;
    font-size: 28px;
    color: #fff;
    cursor: pointer;
}

.manage #menu {
    position: absolute;
    top: -99vh;
    right: 1rem;
    color: #000;
    font-size: 16px;
    border-radius: 0.5rem;
    border: 0px transparent;
    box-shadow: 0 0.2rem 0rem #00000022, 0 0.5rem 2rem #00000022;
    background: #fff;
    width: 300px;
    overflow: hidden;
    outline: none;
}

.manage #menu:focus {
    top: 3.5rem;
}
.manage #menu ul {
    margin: 0px;
    padding: 0px;
    width: 100%;
    overflow: auto;
}
.manage #menu li {
    line-height: 28px;
    padding: 0.5rem;
    cursor: pointer;
}
.manage #menu li svg {
    font-size: 18px;
    position: relative;
    top: 0.2rem;
    margin-right: 0.5rem;
}
.manage #menu li:hover {
    background: var(--primary-light-color);
    color: #fff;
}
.manage .menu:hover {
    color: #00000066;
}

.manage .create-popup {
    z-index: 999;
    left: calc(50% - 150px);
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem 1rem;
}

.buttons div {
    margin-left: 0.5rem;
    width: 1.2rem;
    background: var(--primary-color);
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.button.inactive {
    background: #eee;
    color: #aaa;
    pointer-events: none;
    cursor: default;
}

.selected-info {
    height: 100%;
    align-self: flex-end;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--primary-color);
}

#tags-popup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #00000033;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s;
}

#tags-popup.open {
    z-index: 10;
    opacity: 1;
}

#tags-popup .dialog {
    box-sizing: border-box;
    width: 80%;
    max-width: 650px;
    height: 80%;
    padding: 1rem;
    margin-top: 5rem;
    margin-left: var(--navbar-width);
}

#tags-popup .dialog .tag-picker {
    width: 100%;
}

#tags-popup .dialog .tag-picker .cm-editor {
    width: 100%;
    margin: 0px;
    padding: 0.5rem;
}

.codemirror-tag {
    height: calc(100% - 10rem);
    width: 100%;
    overflow-y: hidden;
}

.codemirror-tag .cm-editor {
    border-radius: 1rem;
    height: 100%;
    overflow-y: hidden;
    background: var(--codemirror-bg);
}

.manage .select-tag [class*="control"],
.manage .select-tag [class*="menu"] {
    background: var(--input-bg);
    color: var(--text-color);
    border: 1px solid var(--item-border-color);
}
.manage .select-tag [class*="singleValue"] {
    color: var(--text-color);
}

.manage .tabulator {
    margin: 1rem;
    width: calc(100% - 4rem);
    padding: 1rem;
    border: 1px solid #00000011;
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0 #00000011;
}
.manage .tabulator input[type="text"] {
    margin: 0px;
    font-size: 14px;
}
.manage .tabulator input[type="text"]:focus {
    color: var(--text-color);
}

.manage .tabulator .tabulator-header {
    border-bottom: 0px transparent;
}
.manage .tabulator .tabulator-row {
    border-bottom: 1px solid var(--container-bg);
}
.manage .tabulator .tabulator-row:hover {
    border-bottom: 1px solid var(--primary-color);
}

.crumbs {
    display: block;
    height: 1rem;
    font-size: 12px;
    text-transform: uppercase;
}
.crumbs span {
    cursor: pointer;
}
.crumbs span.spacer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.crumbs span:hover {
    text-decoration: underline;
}
.manage-create-container {
    width: 100%;
    height: 100%;
    background: #00000022;
    position: absolute;
    z-index: 20;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 0px;
}

.manage-create {
    max-width: 80%;
    width: 600px;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem #00000022;
    overflow: hidden;
    position: absolute;
    margin-top: 10%;
    height: 300px;
    z-index: 25;
}

.manage-create h2 span {
    vertical-align: middle;
}

.manage-create label {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: #00000088;
    margin-left: 1rem;
    margin-top: 1rem;
}

.manage-create input[type="text"] {
    margin-top: 0.5rem;
}

.manage-create .button {
    margin: 1rem;
    background: var(--primary-color);
    color: #fff;
    width: 60px;
    text-align: center;
}
.manage-create .button:hover {
    background: var(--primary-light-color);
}

.manage-create .checkbox {
    margin-left: 1rem;
}
.manage-create .checkbox svg {
    cursor: pointer;
    margin-right: 0.5rem;
    position: relative;
    top: 0.1rem;
}
.manage-create .checkbox span {
    font-weight: 300;
    color: #00000088;
}

.creative-table .tabulator,
.creative-table .tabulator div {
    background: var(--sub-container-bg);
}

.creative-table .button {
    background: var(--primary-color);
    color: #fff;
    margin-right: 1rem;
    animation: manageFadeIn 0.3s ease-in;
    margin-bottom: 1rem;
}

.creative-table video {
    height: 20px;
    box-shadow: 0px 0.2rem 0px #00000011;
}

.creative-table .fallback-image {
    height: 20px;
    box-shadow: 0px 0.2rem 0px #00000011;
}
.creative-table .edited .fallback-image {
    height: 20px;
    box-shadow: 0px 0.2rem 0.2rem var(--primary-color);
}
.creative-table .edited {
    font-style: italic;
    background-color: #00000005;
}

.creative-table .link {
    color: var(--primary-color);
    text-decoration: underline;
}

.manage .creativeset .container {
    /* position: relative; */
    border: 1px solid #00000011;
    border-radius: 0.5rem;
    margin: 1rem;
    margin-right: calc(1rem - 15px);
    box-shadow: 0 0.2rem 0 #00000011;
    /* max-height: 100%; */
    background: var(--container-bg);
    transition: max-height 0.5s;
    animation: manageFadeIn 1s;
    overflow: hidden;
}

.manage .creativeset .select-tag {
    width: 300px;
}
.manage .creativeset .container.collapsed {
    max-height: 55px;
    overflow-y: hidden;
    transition: max-height 0.5s, position 0.2s, box-shadow 0.2s;
}
.manage .creativeset .container.collapsed:hover {
    background: #00000008;
}
.manage .creativeset .container.collapsed:active {
    top: 0.1rem;
    box-shadow: 0 0.1rem #00000022;
}

.manage .creativeset .container .disabled {
    color: var(--input-border-color);
    cursor: default;
}
.manage .creativeset .container .disabled > h3:hover {
    color: var(--input-border-color);
    cursor: default;
}

.manage .creativeset .container .list-header.disabled {
    padding: 0px;
}

.manage .creativeset .container .tag,
.manage .creativeset .container .list-header {
    margin: 0px !important;
    padding-bottom: 4rem;
    background: var(--container-bg);
}
/* .manage .creativeset .container .list-header {
    padding: 1rem;
    cursor: pointer;
} */

.manage .set .container h3 {
    border: none;
    padding: 1rem;
    margin: 0px;
}
.manage .creativeset .container h3:hover,
.manage .creativeset .container .list-header:hover {
    color: var(--primary-color);
}

.manage ul li .item:hover {
    color: var(--primary-item-color);
}
.menu-dialog {
    z-index: 999;
}
.manage .preview-link {
    color: var(--primary-color);
}
.manage .preview-link:hover {
    color: #000;
}

.add-creative {
    position: absolute;
    right: 4rem;
    top: 1rem;
    font-size: 13px;
    color: #555;
    background: #fff;
}
.add-advertiser {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 13px;
    color: #555;
    background: #fff;
}
.copy-folio {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 13px;
    color: #555;
    background: #fff;
    height: 1.1rem;
}
.add-folio {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 13px;
    color: #555;
    background: #fff;
    height: 1.1rem;
}
.manage .add-folio svg,
.manage .copy-folio svg {
    height: 1rem;
}
.add-creativeSet {
    position: absolute;
    right: 3.5rem;
    top: 1rem;
    font-size: 13px;
    color: #555;
    background: #fff;
}

.add-creative:hover {
    color: var(--primary-color);
}

.zip-container {
    padding: 1rem;
    border: 1px solid var(--item-border-color);
    margin: 1rem;
    border-radius: 0.5rem;
    background-color: var(--menu-bg);
}
.zip-container label {
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
    display: inline-block;
}
.zip-container #zip-click-url,
.zip-container #download-button,
.zip-container #size-range {
    box-sizing: border-box;
    max-width: 400px;
    width: 50%;
    margin: 0px 0px 1rem 0px;
}

.zip-container #download-button svg {
    transform: translateY(0.15rem);
    margin-right: 0.5rem;
}
.zip-container #download-button {
    background: var(--primary-color);
    color: #fff;
}

.copy-backdrop {
    background: #00000044;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 4rem;
    z-index: 100;
    opacity: 0;
    animation-name: manageFadeIn;
    animation-duration: 0.2s;
    animation-fill-mode: both;
}
.copy-dialog {
    position: absolute;
    width: 500px;
    padding: 1rem;
    background: var(--menu-bg);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem #00000022;
    left: calc(50% - 250px);
    top: 30%;
    animation-delay: 0.2s;
    animation-name: manageFadeIn;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    overflow: hidden;
}
.copy-dialog input[type="text"] {
    margin: 0px;
    width: calc(100% - 1rem);
    color: var(--text-color);
}

.copy-dialog .button {
    border: 0px;
    background: var(--input-border-color);
    color: var(--text-color);
    margin-left: 1rem;
    margin-top: 1rem;
}
.copy-dialog input[type="submit"] {
    background: var(--primary-color);
    color: #fff;
    width: 5rem;
}
.copy-dialog input[type="submit"].disabled {
    background: var(--primary-color);
    color: #fff;
    width: 5rem;
    opacity: 0.6;
    cursor: wait;
}
.copy-dialog p {
    font-size: 15px;
    font-weight: 300;
}

.copy-dialog .checkbox {
    font-size: 14px;
    font-weight: 300;
    margin-top: 0.5rem;
}

.copy-dialog .checkbox svg {
    margin-right: 0.5rem;
    transform: translateY(0.1rem);
}
.copy-dialog .success {
    box-sizing: border-box;
    background-color: var(--menu-bg);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    padding: 1rem;
    text-align: center;
    opacity: 0;
    animation: manageFadeIn 0.2s;
    animation-fill-mode: both;
}
.copy-dialog .success .icon {
    font-size: 6rem;
    margin-top: 1rem;
    color: #35a94a;
}
.copy-dialog .success span {
    color: var(--primary-color);
}

.copy-move {
    background: var(--inset-bg);
    padding: 0.5rem 0;
}

.copy-move.open .checkbox {
    margin-bottom: 0.5rem;
}

.copy-move .checkbox.align-right {
    text-align: right;
    margin-right: 1rem;
    margin-top: 0rem;
}
.copy-move .checkbox {
    margin-left: 1rem;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
}
.copy-move .checkbox svg.left {
    margin-right: 1rem;
}
.copy-move .checkbox svg.right {
    margin-left: 1rem;
}
.copy-move .checkbox svg {
    transform: translateY(0.125rem);
}

.copy-move .omni-search {
    margin-left: 1rem;
    width: calc(100% - 2rem);
    margin-bottom: 0.5rem;
}
.manage .edit-button {
    position: absolute;
    left: 1.2rem;
    /* border: 1px solid var(--item-border-color); */
    padding: 0.2rem;
    color: #aaa;
    /* border-radius: 0.2rem; */
}

.manage .toolbox {
    margin-top: var(--toolbox-top-margin);
    height: var(--toolbox-height);
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    gap: 0.25rem;
    border: 1px solid var(--item-border-color);
    border-radius: 0.5rem;
    width: 120px;
    padding: 0.5rem;
    background: var(--inset-bg);
    overflow-x: hidden;
    animation-name: expand;
    animation-duration: 0.25s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

.manage .toolbox.unopened {
    border: 1px solid transparent;
    background: initial;
    padding: 0rem;
    margin-bottom: 0.5rem;
    animation: none;
    overflow: visible;
    width: 2rem;
}

.manage .toolbox .button {
    border: 1px solid var(--item-border-color);
    font-size: 14px;
    padding: 0.25rem;
    height: 1rem;
    width: 1rem;
    text-align: center;
    background: var(--input-bg);
}
.manage .toolbox .button.edit {
    width: 1rem;
    background: var(--primary-color);
    color: #fff;
    vertical-align: middle;
    height: 1rem;
    line-height: 1rem;
    padding: 0.75rem;
    font-size: 18px;
}

.manage .toolbox .button.edit svg {
    margin-right: 0.5rem;
    /* transform: translateY(0.125rem); */
}

.manage .toolbox .cancel {
    color: var(--item-border-color);
    transform: translateY(0.3rem);
    margin-left: 0.75rem;
    cursor: pointer;
}
.manage .toolbox .cancel:hover {
    color: #000;
}

.manage .toolbox.disabled .button {
    background-color: var(--container-bg);
    color: var(--item-border-color);
    pointer-events: none;
}

.manage .toolbox .button:hover {
    background: var(--primary-color);
    color: #fff;
}

.manage .toolbox .button.delete:hover {
    background: rgb(244, 118, 118);
}

.manage .dialog-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000044;
    z-index: 99;
}
.manage .dialog-container .dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    margin-left: -300px;
    height: 800px;
    max-height: 80%;
    top: 10%;
    width: 600px;
    border-radius: 1rem;
    overflow: visible;
}
.manage .dialog-container .dialog h2 {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.manage .dialog-container .dialog .description .warning {
    color: var(--warning-color);
}

.manage .dialog-container .dialog .header.warning > h2 {
    background: var(--warning-color);
}
.manage .dialog-container .dialog .header > h2 {
    text-transform: capitalize;
    line-height: 3rem;
}
.manage .dialog-container .dialog .buttons {
    margin-top: 1rem;
    width: 100%;
}

.manage .dialog-container .dialog .buttons .button {
    width: 4rem;
    margin-right: 1rem;
    margin-left: 0px;
}

.manage .dialog-container .dialog .button.submit {
    background: var(--primary-color);
    color: #fff;
    margin-right: 2rem;
}
.manage .dialog-container .dialog .button.submit.disabled {
    background: var(--inset-bg);
    color: var(--item-border-color);
    pointer-events: none;
    cursor: default;
}
.manage .dialog-container .dialog .cancel {
    background: var(--input-border-color);
    color: var(--text-color);
}

.manage .dialog-container .dialog .description {
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid var(--item-border-color);
    margin-bottom: 1rem;
}
.manage .dialog-container .dialog ul li span {
    margin-left: 1rem;
}
.manage .dialog-container .dialog ul {
    list-style-type: none;
    overflow-y: scroll;
    padding: 0px;
    margin: 0px;
    margin-top: 1rem;
    box-sizing: border-box;
}

.manage .dialog-container .dialog label {
    margin-left: 1rem;
    font-size: 14px;
}
.manage .dialog-container .dialog ul input[type="text"] {
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;
    width: calc(100% - 2.25rem);
    border-radius: 0.25rem;
}
.manage .zip-container .checkbox {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.manage .zip-container .checkbox svg {
    margin-right: 0.5rem;
    transform: translateY(0.1rem);
}

.manage .fallback-box.popup {
    box-sizing: border-box;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-left: -300px;
    z-index: 100;
    min-height: 500px;
    width: 600px;
    background: var(--menu-bg);
    box-shadow: 0 0.5rem 1rem #00000022, 0 0.5rem 4rem #00000022;
}
.manage .fallback-box.popup .row.centered {
    gap: 0.5rem;
}
.manage .fallback-box.popup .button {
    display: block;
    height: 1.5rem;
    line-height: 1.5rem;
    color: var(--text-color);
    margin: 0px;
}

.manage .fallback-box.popup video {
    width: 100%;
    height: auto;
    max-height: 350px;
}
.manage .fallback-box.popup video.has-title {
    max-height: 310px;
}

.manage .content .container:first-child,
.manage .content .container:first-child .tag {
    overflow: visible;
}

.manage .content .container.collapsed:first-child {
    overflow: hidden;
}

.manage .creative {
    box-sizing: border-box;
    margin-top: 0;
    margin: 1rem;
    border: 1px solid #00000011;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    background: var(--menu-bg);
    box-shadow: 0 0.1rem 0 #00000011;
}

.manage .expand-all {
    box-sizing: border-box;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--primary-color);
    margin-left: 1.2rem;
    display: block;
    /* transform: translateY(-0.5rem);C */
    cursor: pointer;
    position: relative;
    height: 2rem;
    padding-top: 1rem;
}
.manage .expand-all:hover {
    color: #000;
}
.manage .creative {
    box-sizing: border-box;
    margin-top: 1rem;
    position: relative;
    display: block;
}

.manage .creative.small h4 {
    border: none;
    width: auto;
}
.manage .creative h4 {
    width: calc(100% - 2rem);
    display: inline-block;
    margin: 0px;
    font-size: 16px;
    padding: 0.5rem 1rem;
    font-weight: 400;
    border-bottom: 1px solid #00000011;
}

.manage .creative .title {
    cursor: pointer;
}
.manage .creative .title.changed {
    font-style: italic;
    color: var(--primary-color);
}

.manage .creative .row {
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
    /* background: var(--inset-bg); */
}

.manage .creative .creative-preview {
    box-sizing: border-box;
    padding: 0.5rem;
    height: 150px;
    width: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #00000011;
    border-radius: 0.25rem;
    background: var(--inset-bg);
    /* box-shadow: inset 0 0.25rem 0 #00000022; */
}

.manage .creative .creative-preview.wide {
    width: 350px;
    height: 300px;
}
.manage .creative .creative-preview img,
.manage .creative .creative-preview video {
    max-height: calc(100% - 3rem);
    max-width: 100%;
    width: auto;
    border-radius: 0.25rem;
    object-fit: scale-down;
}
.manage .creative .video-commands {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0rem;
    padding: 0.25rem;
}
.manage .creative .video-commands .button {
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 14px;
    background: var(--menu-bg);
    border-radius: 0.25rem;
    box-shadow: 0 0.1rem 0 #00000022;
}
.manage .creative .video-commands .button:active {
    transform: translateY(0.1rem);
    box-shadow: 0 0 0 #00000000;
}
.manage .creative .video-commands .button svg {
    transform: translate(-0.15rem, -0.15rem);
}
.manage .creative .video-commands .button:hover {
    background: var(--primary-color);
    color: #fff;
}

.manage .creative .creative-details {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
}
.manage .creative .creative-details .detail-item.start {
    justify-content: start;
}

.manage .creative .creative-details .detail-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.manage .creative .creative-details .detail-item label {
    float: left;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: 400;
    width: 210px;
}
.manage .creative .creative-details .detail-item input {
    color: var(--menu-text-color);
    width: 20rem;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #00000011;
    margin: 0px;
    padding: 0.25rem 0.25rem 0.1rem 0.25rem;
    height: 1rem;
    font-size: 14px;
    margin-top: 0.25rem;
    background: var(--menu-bg);
}

.manage .creative .creative-details .detail-item input:focus {
    border-color: var(--input-border-color);
    color: var(--text-color);
}

.manage .creative .creative-details .detail-item select {
    border: none;
    border-bottom: 1px solid #00000011;
    width: 80px;
    font-family: "Noto Sans";
}
.manage .creative.small h4 {
    display: inline-block;
}

.manage .creative-preview .creative-size {
    font-size: 12px;
    margin-top: 0.5rem;
    font-weight: 300;
}

.manage .creative.small .creative-size {
    color: var(--input-border-color);
    font-size: 14px;
}

.manage .creative.small img,
.manage .creative.small video {
    height: 30px;
    width: 100px;
    object-fit: cover;
    animation-name: manageFadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}
.manage .creative.small .small-preview {
    width: 100px;
    height: 30px;
    display: block;
    transform: translateY(0.25rem);
    background: var(--inset-bg);
    float: left;
    margin: 0 0.25rem;
    margin-right: 0rem;
    border-radius: 0.25rem;
    overflow: hidden;
}
.manage .creative.small .small-preview span {
    display: inline-block;
    color: var(--menu-border-color);
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    transform: translateY(0.2rem);
}

.manage .creativeset .button.save-creatives {
    background: var(--primary-color);
    color: #fff;
    /* position: absolute; */
    /* bottom: 2rem;
    right: 2rem; */
    margin: 1rem;
    margin-top: 0rem;
    display: inline-block;
}
.manage .creativeset .button.save-creatives.disabled {
    background: var(--input-border-color);
    color: var(--text-color);
    pointer-events: none;
    cursor: not-allowed;
}

.manage .creative .fallback-box.open .rc-slider {
    margin: 0px;
    margin-top: -0.5rem;
    padding-bottom: 0px;
}
.manage .creative .fallback-box.open .buttons {
    margin-top: -0.5rem;
    padding: 0;
    justify-content: center;
}
.manage .creative .fallback-box.open .buttons .button {
    background: var(--menu-bg);
}

.manage .creative .fallback-box.open {
    background: none;
    margin: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border: none;
    box-shadow: none;
}
.manage .creative .fallback-box.open .row:first-child {
    padding: 0px;
}

.manage .creative .fallback-box.open video {
    max-height: 200px;
}

.manage .creative .button.replace {
    background: var(--menu-bg);
    border: 1px solid var(--input-border-color);
    opacity: 0.6;
    color: var(--text-color);
    display: inline-block;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}
.manage .creative .button.replace svg {
    transform: translateY(0.1rem);
    margin-right: 0.5rem;
}

@keyframes manageFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes expand {
    from {
        width: 1rem;
    }
    to {
        width: 120px;
    }
}
