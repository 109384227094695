.stats {
    padding: 1rem;
}

.stats h4 {
    margin: 0px;
    text-align: center;
    font-weight: 500;
}
.stats .main-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.stats .details,
.stats .graphs {
    box-sizing: border-box;
    width: calc(50% - 0.5rem);
    border: 1px solid #00000011;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 14px;
    box-shadow: 0 0.2rem 0 #00000011;
    background: var(--sub-container-bg);
    transition: transform 0.2s, box-shadow 0.2s;
}

.stats .details:hover,
.stats .graphs:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0 0.3rem 0 #00000011;
}

.stats .graphs {
    display: flex;
    flex-direction: row;
}
.stats .details .stat {
    padding: 0.25rem 0;
    transition: background 0.2s;
}
.stats .details .stat:hover {
    background: #00000008;
}

.stats .details .stat:first-child {
    padding-right: 1rem;
}
.stats .details .stat span {
    font-weight: 400;
}
.stats .details .stat span.value {
    font-weight: 600;
}

.stats .graphs .quartiles {
    height: 100px;
    width: calc(50% - 1rem);
    border-right: 1px solid #00000011;
    margin-right: 1rem;
}
.stats .graphs .completion {
    height: 100px;
    width: 50%;
    position: relative;
}

.stats .graphs .recharts-layer path:focus {
    outline: none;
}

.stats .graphs .quartiles .recharts-layer .recharts-cartesian-axis-ticks {
    font-size: 12px;
}
.stats .graphs .quartiles .recharts-layer .recharts-cartesian-axis-ticks line {
    stroke: #00000000;
}
.stats .graphs .quartiles .recharts-layer path.recharts-area-curve {
    stroke: var(--primary-color);
    fill-opacity: 1;
}
.stats .graphs .quartiles .recharts-layer path.recharts-area-area {
    fill: var(--primary-color);
    fill-opacity: 1;
}
.stats .graphs .quartiles .recharts-tooltip-item {
    color: var(--primary-color) !important;
}
.stats .graphs .quartiles .recharts-tooltip-wrapper {
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 0.2rem 0 #00000033;
}
.stats .graphs .quartiles .recharts-default-tooltip {
    border-radius: 0.5rem;
    background-color: var(--sub-container-bg) !important;
    border: 1px solid var(--item-border-color) !important;
}
.stats .graphs .completion path[name="average"] {
    fill: var(--primary-color);
}
.stats .graphs .completion path[name="total"] {
    fill: #00000033;
}

.stats .graphs .completion .percentage {
    position: relative;
    left: calc(50% - 15px);
    top: -40px;
    width: 30px;
    font-size: 12px;
    color: var(--text-color);
}
