.whatsnew-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #00000022;
    z-index: 100;
    backdrop-filter: blur(0.5rem);
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

.whatsnew {
    box-sizing: border-box;
    position: absolute;
    width: 800px;
    top: 10%;
    left: 50%;
    margin-left: -400px;
    height: 600px;
    background: var(--menu-bg);
    z-index: 101;
    box-shadow: 0 0.5rem 1rem #00000033;
    border-radius: 1rem;
    padding: 1rem;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    color: var(--text-color);
}
.whatsnew .content {
    overflow-y: scroll;
    height: calc(100% - 3rem);
}

.whatsnew ul {
    list-style: none;
    padding: 0px;
}

.whatsnew li {
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--inset-bg);
}

.whatsnew li .image {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;
    align-items: center;
    border-radius: 0.5rem;
    background-color: var(--inset-bg);
    padding-top: 1rem;
}

.whatsnew .image img {
    border-radius: 0.25rem;
    /* width: fit-content; */
    max-height: 200px;
}
.whatsnew .image p {
    font-size: 14px;
    margin-top: 0.5rem;
}

.whatsnew .row.end {
    justify-content: end;
}

.whatsnew .button {
    background: var(--primary-color);
    color: #fff;
    width: 4rem;
    text-align: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
