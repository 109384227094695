#preview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#preview .preview-modal {
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    background: var(--menu-bg);
    box-shadow: 0 1rem 3rem #00000055;
    max-width: 80vw;
}

#preview .row.center {
    justify-content: center;
    margin-top: 0px;
}

.row.warning {
    display: block;
    text-align: center;
}

.row.warning a {
    color: var(--text-color);
}
p.size-warning {
    margin-top: 1rem;
    border: 1px solid var(--warning-color);
    background: var(--warning-bg);
    color: var(--warning-color);
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 400px;
    position: relative;
    margin-left: 50%;
    left: calc(-200px - 1rem);
}

#preview h2 {
    font-weight: 300;
    margin-bottom: 1rem;
    color: var(--text-color);
}

#preview h2 span.advertiser {
    font-weight: 500;
}
.iframe-frame {
    border: 1px solid #000;
}

iframe {
    position: relative;
    max-width: 90vw;
    max-height: 70vh;
    border: 1px solid #000;
}
