.help-container {
    margin-left: var(--navbar-width);
    width: calc(100% - var(--navbar-width));
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: var(--text-color);
}

.help-container h3 {
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0px;
    padding: 0.5rem;
    padding-bottom: 0;
}
.page {
    box-sizing: border-box;
    width: 70%;
    height: 80vh;
    margin-top: 5%;
    background: var(--menu-bg);
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 0rem 1rem #00000033;
}

.help-container .page .bold {
    font-weight: 600;
    width: auto;
    margin: 0px;
}

.help-container .details {
    padding: 0.5rem;
}

.help-container .spacer {
    display: block;
    height: 2rem;
}

h3.active {
    color: var(--primary-color);
    border-left: 3px solid var(--primary-color);
}
h3.active + .details {
    border-left: 3px solid var(--primary-color);
    border-color: var(--primary-color);
}
