.video-editor {
    position: relative;
    margin-top: 0px;
    z-index: 5;
    padding-left: calc(var(--navbar-width) + 1rem);
    color: #fff;
    width: 100%;
    height: 100vh;
    /* background: var(--dark-background, #272d37); */
    animation: fadeIn;
    animation-iteration-count: 1;
    animation-duration: 0.3s;
}

.video-editor.out {
    animation: fadeIn;
    animation-iteration-count: 1;
    animation-duration: 0.3s;
    animation-direction: reverse;
}
.video-drop {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
}

.video-main {
    padding: 1rem;
    background: var(--menu-bg);
    border-radius: 1rem;
    box-shadow: 0 1rem 3rem #00000055;
    transition: 0.4s;
    color: var(--text-color);
}
.video-main.hovering {
    padding: 1rem;
    background: var(--menu-bg);
    border-radius: 1rem;
    box-shadow: 0 3rem 5rem #00000055;
    transition: 0.2s;
    color: var(--text-color);
    transform: scale(1.1);
}
.center-vertically {
    align-self: center;
}

.drop-zone {
    /* background: #fff; */
    padding: 2rem;
    border-radius: 1rem;
    /* box-shadow: 0 1rem 3rem #00000055; */
}
.drop-zone .plus {
    color: var(--primary-color);
    font-size: 10rem;
    text-align: center;
    margin: 0 auto;
    display: block;
    transition: 0.3s;
}

.video-main.hovering .drop-zone .plus {
    transform: scale(1.2);
}

.drop-zone .error {
    color: rgb(204, 112, 112);
}

.drop-zone p {
    color: var(--text-color);
    text-align: center;
}

.drop-zone p.small {
    font-size: 12px;
}

.video-container {
    position: relative;
    /* width: 600px; */
    overflow: hidden;
    box-sizing: border-box;
    margin: 0 auto;
    transition: 0.5s;
    transition-timing-function: ease-in-out;
    border-radius: 0.5rem;
    overflow: hidden;
}
.video-drop video {
    /* box-sizing: border-box; */
    border: 1px solid var(--input-border-color);
    margin: 0 auto;
    animation: zoomIn;
    animation-iteration-count: 1;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    display: block;
    margin-bottom: 1rem;
}

.video-drop input[type="number"],
.video-drop input[type="text"] {
    font-size: 12px;
    border-radius: 0.2rem;
    border: 1px solid var(--input-border-color);
    background: var(--input-bg);
    height: calc(24px - 0.4rem);
    width: 50px;
    margin-right: 1rem;
    padding: 0.2rem;
    color: var(--text-color);
}

.video-drop input[type="number"]:disabled {
    background: var(--container-bg);
    color: var(--input-border-color);
}
.video-drop .row {
    display: flex;
    flex-direction: row;
    position: relative;
    pointer-events: all;
}
.video-drop .centered {
    justify-content: center;
    align-items: baseline;
    color: #00000088;
}
.video-drop .space-between {
    justify-content: space-between;
    margin-top: 0px;
}

.play-btn {
    display: block;
    color: var(--primary-color);
    font-size: 28px;
    transition: 0.2s;
    cursor: pointer;
    max-height: 28px;
    width: 24px;
    margin: 0.5rem 0.5rem 0 0;
}
.play-btn:hover {
    color: #000000dd;
}

.size-select {
    font-size: 14px;
    width: 150px;
    margin-right: 1rem;
    font-size: 14px;
}
.video-drop .size-select * {
    min-height: 0px;
}

.video-drop .size-select input {
    height: 16px;
}
.video-drop .size-select [class*="-ValueContainer"] {
    padding: 0 0.2rem;
}
#file-input {
    position: absolute;
    display: none;
}

#file-browser {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}
#file-browser:hover {
    text-decoration: none;
}
.iab-item {
    color: var(--text-color) !important;
}

.iab-item-selected {
    background: var(--primary-color) !important;
}

.iab-item-focused {
    background: var(--primary-light-color) !important;
    color: #fff !important;
}
.button-container {
    margin-bottom: 1rem;
}
.continue-btn {
    background: var(--primary-color);
    color: #fff;
    border: none;
    height: 36px;
    width: 130px;
    margin-left: 0.5rem;
}
.continue-btn:hover {
    background: var(--primary-light-color);
}

.crop-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 130px;
    box-sizing: border-box;
    border: 1px solid #00000033;
    color: var(--text-color);
    height: 36px;
    line-height: 20px;
    vertical-align: middle;
    background: var(--container-bg);
    margin-left: 0.5rem;
}

.crop-btn:hover {
    background: #00000011;
}

.crop-btn:active {
    box-shadow: none;
    transform: translateY(0.2rem);
}
.crop-btn > svg {
    color: var(--primary-color);
    margin-right: 0.5rem;
}

.fullscreen-btn {
    color: var(--primary-color);
}

.video-drop .blocker {
    position: absolute;
    width: calc(100% - 0.5rem);
    height: calc(100% - 1rem);
    border-radius: 0.5rem;
    z-index: 99;
    background-color: var(--primary-color);
    opacity: 0;
    top: 0px;
    left: 0.5rem;
    cursor: pointer;
    transition: 0.1s;
}
.video-drop .blocker:hover {
    opacity: 0.15;
}

.crop-settings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5rem;
    margin-left: 0.5rem;
    border: 1px solid var(--input-border-color);
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0 #00000022;
    margin-bottom: 1rem;
    background-color: var(--container-bg);
    width: 350px;
    transition: 0.1s;
}

.crop-settings.active {
    border: 1px solid var(--primary-color);
}
.crop-settings i {
    display: inline-block;
    font-style: normal;
    position: relative;
}

.crop-settings label {
    color: var(--text-color);
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: -0.4rem;
}
.crop-settings .selector {
    position: relative;
    top: 0.75rem;
    left: 0.5rem;
    cursor: pointer;
}
.crop-settings .selector circle,
.crop-settings .selector path {
    color: var(--primary-color);
    stroke: var(--primary-color);
}
.crop-settings #name {
    position: relative;
    width: 100%;
}
.crop-settings #name input {
    width: calc(100% - 0.5rem);
    margin-right: 1rem;
    margin-bottom: 0.5rem;
}

.crop-settings .dims {
    position: relative;
    top: 0.3rem;
}
.crop-settings .dims input {
    height: 18px;
    position: relative;
    top: 0.4rem;
}

.crop-settings [class*="control"],
.crop-settings [class*="menu"] {
    background: var(--input-bg);
    border: 1px solid var(--item-border-color);
    color: var(--text-color);
}
.crop-settings [class*="singleValue"] {
    color: var(--text-color);
}

.crop-settings .trash-icon {
    position: relative;
    top: 1.3rem;
    left: 1.3rem;
    color: var(--input-border-color);
    cursor: pointer;
    transition: 0.1s;
}
.crop-settings .trash-icon:hover {
    color: #cc7070;
}

.rc-slider .rc-slider-track {
    background-color: var(--primary-color);
}

.rc-slider .rc-slider-handle {
    border-color: var(--primary-color);
    background-color: #fff;
    opacity: 1;
}
.rc-slider .rc-slider-handle:hover {
    border-color: var(--container-bg);
    background-color: var(--primary-color);
}
.rc-slider .rc-slider-handle.rc-slider-handle-dragging:hover,
.rc-slider .rc-slider-handle.rc-slider-handle-dragging:active {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 5px var(--primary-color);
}

.video-editor .fallback svg {
    transform: translateY(0.8rem);
    height: 1.22rem;
    width: 1.2rem;
    color: var(--primary-color);
    cursor: pointer;
}
.video-editor .fallback svg:hover {
    color: #000;
}

.rc-slider.fallback-slider .rc-slider-handle {
    border-radius: 0px;
}

.fallback-box {
    box-sizing: border-box;
    font-size: 14px;
    margin-left: 1rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    max-height: 1.75rem;
    overflow: hidden;
    border: 0px solid transparent;
    transition: max-height 0.1s, box-shadow 0.2s;
    box-shadow: none;
    width: 300px;
}
.fallback-box.popup.open {
    box-sizing: border-box;
    border: 1px solid var(--input-border-color);
    max-height: 500px;
    box-shadow: 0 0.2rem 2rem #00000055;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-left: -300px;
    width: 600px;
    z-index: 10;
    background: var(--menu-bg);
}

.fallback-box.open {
    border: 1px solid var(--input-border-color);
    max-height: calc(200px + 3rem);
    box-shadow: 0 0.2rem 0rem #00000022;
    width: 300px;
    z-index: 10;
    background: var(--menu-bg);
}

.fallback-box.open #video,
.fallback-box.open #fallback-video,
.fallback-box.open .button {
    display: block;
}
.fallback-box #video,
.fallback-box #fallback-video {
    max-height: 200px;
    display: none;
}

.fallback-box .row {
    gap: 1rem;
    margin-top: 0px;
}

.fallback-box .box-title {
    color: var(--primary-color);
    text-align: right;
    margin-top: 0px;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.fallback-box .button {
    background: var(--inset-bg);
    padding: 0.2rem 0.5rem;
    font-size: 14px;
    margin-top: 0px;
    display: none;
}
.fallback-box .button svg {
    margin-right: 0.5rem;
    transform: translateY(0.1rem);
}

.fallback-box video {
    max-width: 100%;
    max-height: 160px;
    border: none;
    border-radius: 0.5rem !important;
}

.fallback-box .rc-slider {
    margin-bottom: 1rem;
    width: 90%;
}
.crop-settings .fallback-box.open {
    margin: 0.5rem 0;
    max-height: calc(200px + 5rem);
}
.crop-settings .fallback-box {
    width: 100%;
}

.video-editor .video-drop .video-main .col.top {
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}
.overlay-upload {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
    position: absolute;
    bottom: 0.5rem;
}
.overlay-upload input {
    display: none;
}

.overlay-upload-button {
    display: inline-block;
    background: var(--primary-color);
    color: #ffffff;
}
.overlay-upload-button svg {
    transform: translateY(0.1rem);
    margin-right: 0.5rem;
    font-size: 18px;
}

.overlay-container svg.remove {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
}

.overlay-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    width: calc(100% - 1rem);
    padding: 0.5rem;
    border: 1px solid var(--item-border-color);
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0 #00000011;
    position: relative;
}

.overlay-position {
    width: calc(43px + 0.6rem);
    height: calc(43px + 0.6rem);
    display: flex;
    flex-direction: column;
    background: var(--item-border-color);
    gap: 1px;
    border: 1px solid var(--item-border-color);
    border-radius: 0.2rem;
    overflow: hidden;
    box-shadow: 0 0.2rem 0 #00000011;
    margin-right: 0.5rem;
}
.overlay-position .position-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.overlay-position .blank {
    display: block;
    background: var(--menu-bg);
    height: 14px;
    width: 14px;
}

.overlay-position .item {
    background: var(--menu-bg);
    cursor: pointer;
    font-size: 14px;
    padding: 0.1rem;
}
.overlay-position .blank.item {
    cursor: default;
}
.overlay-position .blank.item:hover {
    background: var(--menu-bg);
}

.overlay-position .item:hover {
    background: var(--primary-color);
    color: #fff;
}

.overlay-position .item.active {
    background: var(--primary-color);
    color: #fff;
}

.overlay-preview {
    position: absolute;
    z-index: 9;
    display: flex;
    flex-direction: row;
}

.overlay-preview.top-left {
    flex-direction: row;
    align-items: start;
}
.overlay-preview.top {
    justify-content: center;
    align-items: flex-start;
}
.overlay-preview.top-right {
    flex-direction: row-reverse;
    align-items: start;
}
.overlay-preview.left {
    flex-direction: row;
    align-items: center;
}
.overlay-preview.right {
    flex-direction: row-reverse;
    align-items: center;
}
.overlay-preview.bottom-left {
    flex-direction: row;
    align-items: end;
}
.overlay-preview.bottom {
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}
.overlay-preview.bottom-right {
    flex-direction: row-reverse;
    align-items: end;
}
.overlay-preview.full {
    flex-direction: row;
    align-items: start;
}
.overlay-preview.full img {
    height: 100%;
    width: 100%;
}

.video-editor .title {
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    width: 100%;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
