.omni-search {
    /* width: 430px; */
    width: 100%;
    margin-bottom: 1rem;
}

.omni-search span.badge {
    font-size: 10px;
    display: inline-block;
    text-transform: uppercase;
    background: #888;
    color: #fff;
    padding: 0.2rem;
    margin-right: 0.5rem;
    border-radius: 0.2rem;
    transform: translateY(-0.1rem);
    width: 65px;
    text-align: center;
}

.omni-search span.badge.Advertiser {
    background: #754fc5;
}
.omni-search span.badge.Folio {
    background: #4fc588;
}
.omni-search span.badge.CreativeSet {
    background: #9ea634;
}

.omni-search [class*="-control"] {
    background: var(--menu-bg);
    border-color: var(--input-border-color);
    caret-color: var(--text-color);
    position: relative;
    z-index: 99;
}

.omni-search [class*="-menu"] {
    background: var(--menu-bg);
    border-color: var(--inset-bg);
    color: var(--text-color);
    box-shadow: 0 0.5rem 2rem #00000066, 0 0 0 9999px #00000044;
}

.omni-search [class*="-Input"] {
    color: var(--text-color);
}
.omni-search [class*="-IndicatorsContainer"] {
    display: none;
}

.omni-search .search-item-focused {
    background: var(--primary-color);
}
