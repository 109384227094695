:root {
    --item-height: 300px;
}

.start-container {
    width: 100%;
    padding-top: calc(40vh - var(--item-height) / 2);
    padding-left: var(--navbar-width);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.start-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background: var(--menu-bg);
    height: 300px;
    width: 200px;
    transition: 0.2s;
    padding: 0px;
    overflow: hidden;
    box-shadow: 0 0.2rem 0 #00000022, 0 0 1rem #00000022;
}

.start-item:hover {
    box-shadow: 0 0.4rem 0.5rem #00000022;
    transform: translateY(-0.2rem);
}

.start-item:active {
    transform: translateY(0.2rem);
    box-shadow: 0 0 0 #00000088;
}

.start-item svg {
    font-size: 8rem;
    color: var(--primary-color);
}

.start-item.big svg {
    font-size: 18rem;
    transform: rotate(10deg) translateY(4rem);
    opacity: 0.2;
}
.start-item .title {
    background: var(--inset-bg);
    width: 100%;
    height: 3rem;
    margin-top: 4rem;
}
.start-item span {
    color: var(--text-color);
    font-weight: 300;
    font-size: 18px;
    line-height: 3rem;
}

.start-item .col {
    display: flex;
    flex-direction: column;
}
