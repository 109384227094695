:root {
    --blip-size: 8px;
    --border-color: #888;
    --cropper-color: #fff;
}

.cropper.overlay {
    border-color: #fff;
}

.cropper {
    position: relative;
    box-sizing: border-box;
    border: 1px solid var(--cropper-color);
    box-shadow: 0 0 0 1300px #000000aa;
    background: none;
    display: block;
    z-index: 10;
    opacity: 1;
    transition: opacity 0.3s;
}

.cropper.hidden {
    opacity: 0;
}

.cropper-grid.overlay {
    display: block;
    position: relative;
    width: 100%;
}

.cropper-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid: 33.33% 33.34% 33.33% / 33.33% 33.34% 33.33%;
}
.cropper-grid .left {
    border-right: 1px dashed #aaa;
}

.cropper-grid > div {
    height: 100%;
    width: 100%;
}

.cropper-grid > div::after {
    border: 1px solid #000;
    z-index: 20;
}

.cropper-grid .top-left {
    grid-column-start: 1;
    grid-row-start: 1;
    border-right: 1px dashed var(--border-color);
    border-bottom: 1px dashed var(--border-color);
}
.cropper-grid .top-left span.name {
    background: var(--cropper-color);
    padding-right: 1rem;
    padding-left: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: #000;
    text-transform: uppercase;
    font-size: 11px;
    position: absolute;
}
.cropper-grid .top-mid {
    grid-column-start: 2;
    grid-row-start: 1;
    border-bottom: 1px dashed var(--border-color);
}

.cropper-grid .top-right {
    grid-column-start: 3;
    grid-row-start: 1;
    border-left: 1px dashed var(--border-color);
    border-bottom: 1px dashed var(--border-color);
}

.cropper-grid .mid-left {
    grid-column-start: 1;
    grid-row-start: 2;
    border-right: 1px dashed var(--border-color);
}
.cropper-grid .mid-mid {
    grid-column-start: 2;
    grid-row-start: 2;
}
.cropper-grid .mid-right {
    grid-column-start: 3;
    grid-row-start: 2;
    border-left: 1px dashed var(--border-color);
}

.cropper-grid .bottom-left {
    grid-column-start: 1;
    grid-row-start: 3;
    border-right: 1px dashed var(--border-color);
    border-top: 1px dashed var(--border-color);
}
.cropper-grid .bottom-mid {
    grid-column-start: 2;
    grid-row-start: 3;
    border-top: 1px dashed var(--border-color);
}

.cropper-grid .bottom-right {
    grid-column-start: 3;
    grid-row-start: 3;
    border-left: 1px dashed var(--border-color);
    border-top: 1px dashed var(--border-color);
}

.cropper-grid .top-left::after {
    content: "";
    display: block;
    height: var(--blip-size);
    width: var(--blip-size);
    position: relative;
    left: calc(0px - var(--blip-size) / 2);
    top: calc(0px - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: var(--blip-size);
}

.top-mid::after {
    content: "";
    display: block;
    height: var(--blip-size);
    width: calc(var(--blip-size));
    position: relative;
    left: calc(50% - var(--blip-size) / 2);
    top: calc(0px - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: calc(var(--blip-size));
}

.cropper-grid .top-right::after {
    content: "";
    display: block;
    height: var(--blip-size);
    width: var(--blip-size);
    position: relative;
    left: calc(100% - var(--blip-size) / 2);
    top: calc(0px - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: var(--blip-size);
}

.mid-left::after {
    content: "";
    display: block;
    height: calc(var(--blip-size));
    width: var(--blip-size);
    position: relative;
    top: calc(50% - var(--blip-size) / 2);
    left: calc(0px - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: var(--blip-size);
}

.cropper-grid .mid-right::after {
    content: "";
    display: block;
    height: calc(var(--blip-size));
    width: var(--blip-size);
    position: relative;
    top: calc(50% - var(--blip-size) / 2);
    left: calc(100% - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: var(--blip-size);
}

.cropper-grid .bottom-left::after {
    content: "";
    display: block;
    height: var(--blip-size);
    width: var(--blip-size);
    position: relative;
    left: calc(0px - var(--blip-size) / 2);
    top: calc(100% - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: var(--blip-size);
}

.cropper-grid .bottom-mid::after {
    content: "";
    display: block;
    height: var(--blip-size);
    width: calc(var(--blip-size));
    position: relative;
    top: calc(100% - var(--blip-size) / 2);
    left: calc(50% - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: calc(var(--blip-size));
}

.cropper-grid .bottom-right::after {
    content: "";
    display: block;
    height: var(--blip-size);
    width: var(--blip-size);
    position: relative;
    left: calc(100% - var(--blip-size) / 2);
    top: calc(100% - var(--blip-size) / 2);
    background: var(--cropper-color);
    border-radius: var(--blip-size);
}

.cropper-grid div.safe-landscape {
    border: 1px dashed #fff;
    background: #ffffff44;
    position: absolute;
    left: 13.5%;
    top: 6.5%;
    width: 72.89%;
    height: 87%;
}
.cropper-grid div.safe-portrait {
    border: 1px dashed #fff;
    background: #ffffff44;
    position: absolute;
    left: 36.25%;
    top: 6.5%;
    width: 27.1%;
    height: 87%;
}

.cropper .resize-handler {
    border: 1px solid #000;
    background: #fff;
    border-radius: 50%;
    width: 0.5rem !important;
    height: 0.5rem !important;
}
.resize-handler.tl {
    transform: translate(0.4rem, 0.4rem);
}
.resize-handler.tr {
    transform: translate(-0.4rem, 0.4rem);
}
.resize-handler.bl {
    transform: translate(0.4rem, -0.4rem);
}
.resize-handler.br {
    transform: translate(-0.4rem, -0.4rem);
}
