.picker {
    position: fixed;
    left: 50%;
    margin-left: -125px;
    display: flex;
    justify-content: left;
    width: 100%;
    max-height: 100%;
    height: 100%;
    transition: width 0.2s, margin-left 0.3s;
    margin-top: 1rem;
}
.picker .dialog .left {
    box-sizing: border-box;
    width: 270px;
    min-width: 270px;
    padding: 1rem 0rem 1rem 1rem;
}

.dialog {
    width: 250px;
    background: var(--menu-bg);
    padding: 0px;
    box-shadow: 0 0.5rem 2rem #00000033;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
}

.dialog h2 {
    margin-top: 0px;
    font-weight: 200;
    text-align: center;
    color: var(--text-color);
}
.dialog h2.uncentered {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dropzone {
    box-sizing: border-box;
    width: 100%;
    min-height: 100px;
    background: var(--inset-bg);
    border: 1px solid transparent;
    padding: 1rem;
    padding-top: 1.2rem;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    color: var(--text-color);
    transition: 0.2s;
    box-shadow: inset 0 0.2rem 0 #00000022;
}

.pseudo-file {
    border: 1px solid #fff;
    background: #fff;
    border-radius: 0.1rem;
    border-top-right-radius: 0.5rem;
    height: 50px;
    width: 35px;
    margin: 0 auto;
    box-shadow: 0rem 0.2rem 0rem #00000033;
}

#video-preview {
    /* width: 100%; */
    max-height: 150px;
    max-width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.2rem 0rem #00000033;
}
.filename {
    /* background: #2577b2; */
    background: var(--menu-bg);
    color: var(--text-color);
    padding: 0.2rem;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.2rem 0rem #00000022;
    font-size: 14px;
    margin-top: 0.5rem;
    text-overflow: ellipsis;
    overflow-x: hidden;
}
.file-details {
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
}

.file-details label {
    color: #00000088;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 1rem;
}
.file-details input[type="text"] {
    /* border-radius: 0.2rem; */
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.2rem;
    padding-left: 0px;
    font-size: 16px;
    margin: 0.2rem 0;
    color: #00000088;
    outline: none;
}
.file-details input[type="text"]:focus-within {
    border-color: var(--primary-color);
}
.file-details input[type="text"]#advertiser-name,
.file-details input[type="text"]#campaign-name {
    background: #fff;
    color: var(--primary-color);
    cursor: pointer;
}

.file-details input[type="button"] {
    margin-top: 1rem;
    background: var(--primary-color);
    border: 0px solid transparent;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: 0.2s;
}
.file-details input[type="button"]:hover {
    background: var(--primary-color);
    cursor: pointer;
}

.picker .advertisers {
    max-width: 0px;
    overflow: hidden;
    transition: max-width 0.5s;
    box-sizing: border-box;
}
.dialog.expanded {
    width: 800px;
}
.picker .advertisers.expanded {
    width: 620px;
    max-width: 620px;
    border-left: 1px solid #eee;
    margin-left: 1rem;
    padding-left: 1rem;
}
.picker .advertisers input[type="text"] {
    border: none;
    border: 1px solid var(--input-border-color);
    border-radius: 0.5rem;
    background-color: var(--input-bg-on-dark);
    font-size: 16px;
    color: var(--text-color);
    width: calc(100% - 2rem);
    padding: 0.5rem;
    outline: none;
}

.picker .advertisers input[type="text"]:autofill,
.picker .advertisers input[type="text"]:-webkit-autofill,
.picker .advertisers input[type="text"]:-webkit-autofill:hover,
.picker .advertisers input[type="text"]:-webkit-autofill:active,
.picker .advertisers input[type="text"]:-webkit-autofill:focus {
    /* box-shadow: inset 0 4rem 0.2rem var(--input-bg-autofill); */
    border: 1px solid var(--input-border-color);
}

.picker .advertisers input[type="text"]:focus-within {
    border-color: var(--primary-color);
    color: var(--text-color);
}
.advertiser-picker,
.folder-picker,
.order-picker,
.summary {
    max-height: 2.5rem;
    overflow-y: hidden;
    transition: max-height 0.3s, background 0.3s;
    background: var(--menu-bg);
    padding-left: 1rem;
}
.order-picker,
.folder-picker,
.summary {
    border-top: 1px solid var(--item-border-color);
}

.advertiser-picker h2,
.order-picker h2,
.folder-picker h2,
.summary h2 {
    font-size: 18px;
    margin-top: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--text-color);
}
.summary h2 {
    margin-top: 0px;
}

.advertiser-picker input[type="text"],
.folder-picker input[type="text"],
.order-picker input[type="text"] {
    border: 1px solid var(--input-border-color);
    background: var(--input-bg-on-dark);
}

h2 .bold svg {
    position: relative;
    top: 0.2rem;
    color: var(--text-color);
}
.advertiser-picker.open,
.order-picker.open,
.folder-picker.open,
.summary.open {
    height: calc(100% - (2.5rem * 3));
    max-height: calc(100% - (2.5rem * 3));
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}
.order-picker.open,
.folder-picker.open,
.summary.open {
    background: var(--inset-bg);
    box-shadow: inset 0 0.2rem 0rem #00000022;
}
.summary.open {
    padding-left: 0px;
    border-bottom-right-radius: 1rem;
}

.advertiser-picker.open h2,
.order-picker.open h2,
.folder-picker.open h2,
.summary.open h2 {
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.advertiser-picker.open {
    background: var(--inset-bg);
    padding-left: 1rem;
}

.advertiser-picker h2,
.folder-picker h2,
.order-picker h2 {
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 7px;
}
.picker .advertisers ul {
    height: calc(100% - 140px);
    overflow-y: scroll;
    list-style-type: none;
    padding-left: 0px;
    padding-right: 0px;
}
.picker .advertisers li {
    padding: 0.5rem;
    border-bottom: 1px solid var(--item-border-color);
    color: var(--text-color);
    cursor: pointer;
}
.picker .advertisers li:hover {
    background: var(--primary-color);
    color: #fff;
}

.check {
    background: #ddd;
    color: #fff;
    display: inline-block;
    height: 20px;
    width: 20px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 50%;
    margin-right: 0.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: default;
}

.check.checked {
    background: var(--primary-color);
    animation-name: checked-blip;
    animation-duration: 0.2s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
}
.check.current {
    animation-name: checked-current-effect;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.steps {
    animation-name: steps-fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
}

.dropped-file {
    animation-name: droppedfile-zoom;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.dropped-file .details {
    display: flex;
    flex-direction: column;
}

.dropped-file .details .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.dropped-file .details {
    font-size: 12px;
}
.dropped-file .details .title {
    font-weight: 600;
    text-transform: uppercase;
}

.dropzone .warning {
    font-size: 36px;
    color: rgb(230, 68, 56);
    margin: 1rem auto;
    text-shadow: 0 0.2rem 0 #00000033;
}
.dropzone .warning-text {
    font-size: 14px;
}

.summary .row {
    margin-top: 1rem;
    width: 100%;
    display: flex;
}
.dir.row {
    flex-direction: row;
}
.dir-col {
    flex-direction: column;
    align-items: center;
}
.centered {
    justify-content: center;
}

.summary .item {
    width: 100%;
}

.summary .item > span:first-child {
    width: 100px;
    margin-right: 1rem;
    font-size: 18px;
    font-weight: 200;
    display: inline-block;
}
.summary .item span:last-child {
    border: none;
    border-radius: 0;
    padding: 0.2rem;
    padding-left: 0px;
    font-size: 18px !important;
    margin: 0.2rem 0;
    color: #000;
    outline: none;
    width: 100%;
    background: #fff;
    font-weight: 300;
}

.summary .down-arrow {
    font-size: 24px;
    color: #ccc;
}

.summary input[type="text"] {
    border: 1px solid var(--item-border-color);
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 18px;
    margin: 0.2rem 0;
    color: var(--text-color);
    outline: none;
    background: var(--input-bg);
    width: calc(100% - 140px);
}

.summary label {
    width: 100%;
    color: #00000088;
    font-size: 12px;
    text-transform: uppercase;
}

.summary-card {
    box-sizing: border-box;
    position: relative;
    background: var(--menu-bg);
    /* border: 1px solid #eee; */
    border-radius: 0.5rem;
    width: calc(100% - 2rem);
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0 0.2rem 0 #00000022;
    max-height: 70%;
    overflow-y: scroll;
}

.summary-card {
    padding-bottom: 2rem;
}

.summary-card .select-tag div[class$="control"] {
    border-radius: 0.5rem;
    border-color: var(--input-border-color);
    background-color: var(--input-bg);
    color: var(--text-color);
}

.summary-card .select-tag div[class$="singleValue"] {
    color: var(--text-color) !important;
}

.summary-card .select-tag div[class$="-menu"] {
    border: 1px solid var(--primary-light-color);
    background-color: var(--input-bg);
    font-size: 14px;
    position: relative;
}
.summary-card .select-tag div[class$="MenuList"] {
}
.summary-card .select-tag div[class$="-ValueContainer"] {
    font-size: 14px;
}
.summary-card .row.dir-row .item:first-child .select-tag {
    width: calc(100% - 1rem);
}
.summary-card label {
    color: var(--text-color);
}

.summary-card .row input[type="text"],
.summary-card .row input[type="text"]:autofill,
.summary-card .row input[type="text"]:-webkit-autofill {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    font-size: 14px;
    background-color: var(--input-bg);
    color: var(--text-color);
}

.summary-card .row {
    margin-top: 0.5rem;
}
.summary-card .row.dir-row:first-child {
    margin-top: 0px;
}

.summary .change {
    font-size: 11px;
    text-transform: uppercase;
    color: var(--primary-color);
    display: inline-block;
    position: relative;
    top: -8px;
    left: 0.5rem;
    cursor: pointer;
}

h2.clickable {
    box-sizing: border-box;
    cursor: pointer;
    padding: 0.5rem;
    height: 2rem;
}

.summary input[type="button"] {
    padding: 1rem;
    color: #fff;
    background: var(--primary-color);
    border-radius: 0.5rem;
    width: calc(100% - 2rem);
    border: none;
    display: inline-block;
    margin-left: 1rem;
    box-shadow: 0 0.2rem 0 #00000022;
    font-size: 18px;
    font-weight: 300 !important;
    cursor: pointer;
}
.summary input[type="button"]:hover {
    background: var(--primary-light-color);
}
.summary input[type="button"].disabled {
    pointer-events: none;
    background: #00000044;
}

.new-btn {
    background: var(--primary-color);
    color: #fff;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0.5rem;
    margin-right: 1rem;
    box-shadow: 0 0.2rem 0 #00000022;
    cursor: pointer;
    transition: 0.1s;
}
.new-btn:hover {
    background: var(--primary-light-color);
}

.folder-creator,
.advertiser-creator {
    height: calc(100% - (2.5rem * 3));
    max-height: calc(100% - (2.5rem * 3) - 2rem);
    background: var(--inset-bg);
    padding: 1rem;
}
.advertiser-creator span.cancel {
    text-transform: uppercase;
    font-size: 14px;
    color: var(--primary-color);
    cursor: pointer;
}

.advertiser-creator label,
.folder-creator label,
.creativeset-creator label {
    font-size: 14px;
    text-transform: uppercase;
    color: var(--text-color);
}
.advertiser-creator input[type="text"] {
    width: calc(100% - 1rem);
}
.advertiser-creator input[type="button"],
.folder-creator input[type="button"],
.creativeset-creator input[type="button"] {
    width: 48%;
    border: 0px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
    box-shadow: 0 0.2rem 0 #00000022;
    font-size: 16px;
    font-weight: 300;
}

.advertiser-creator .create:hover {
    background: var(--primary-light-color);
}
.advertiser-creator .create,
.folder-creator .create,
.creativeset-creator .create {
    background: var(--primary-color);
    color: #fff;
}
.advertiser-creator .cancel,
.folder-creator .cancel,
.creativeset-creator .cancel {
    background: var(--container-bg);
}
.advertiser-creator .row,
.folder-creator .row,
.creativeset-creator .row {
    display: flex;
    justify-content: space-between;
}

.folder-creator h2 {
    color: var(--text-color);
}

.creativeset-creator .row {
    width: calc(100% - 1rem);
}

.showDetails {
    display: block;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    opacity: 0.6;
}

.create-popup {
    position: absolute;
    height: 320px;
    width: 300px;
    background: var(--menu-bg);
    border-radius: 1rem;
    box-shadow: 0 0.5rem 2rem #00000033, 0 0 0 99999px #00000055;
    top: 20%;
    animation-name: steps-fadeIn;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    z-index: 1000;
    color: var(--text-color);
}

.create-popup p {
    text-align: center;
    font-weight: 300;
}

.create-popup .icon {
    display: block;
    margin: 20% auto;
}
.create-popup .icon.spin {
    animation: spin 2s infinite linear;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.create-popup .icon.success {
    color: #35a94a;
    margin-bottom: 1rem;
}

.create-popup .upload-progress-container {
    display: block;
    width: calc(100% - 2rem);
    background: var(--inset-bg);
    height: 2rem;
    margin-left: 1rem;
    border-radius: 0.5rem;
    box-shadow: inset 0 0.2rem #00000022;
    overflow: hidden;
}

.create-popup .upload-progress-completion {
    background: var(--primary-color);
    height: 100%;
    transition: width 0.2s;
}

.video-list-preview .title {
    font-size: 12px;
    clear: both;
    display: block;
    margin-bottom: 1rem;
}
.video-list-preview video {
    background: #ffffff;
    border-radius: 0.5rem;
    border: 1px solid var(--input-border-color);
    height: 50px;
    padding: 0rem;
    box-shadow: 0 0.2rem 0 #00000033;
    clear: both;
}

.summary p.select-label {
    color: #00000088;
}

.video-preview-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}
.video-preview-container video {
    border: 0px solid transparent;
    overflow: hidden;
    clear: none;
    margin-left: 1rem;
}
.dropzone .delete {
    background: var(--menu-bg);
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    line-height: 1.7rem;
    transition: 0.1s;
    position: relative;
    top: -1rem;
    margin-left: -0.5rem;
    padding: 0px;
    transform: scale(0.8);
}
.dropzone .delete:hover {
    background: rgb(222, 57, 82);
    color: #fff;
}
.dropzone .button.edit:hover {
    background: var(--primary-light-color);
}
.dropzone .button.edit {
    font-size: 12px;
    background: var(--primary-color);
    color: #fff;
    width: 4rem;
    padding: 0.2rem;
    margin: 0 auto;
}

.summary input[type="text"].error {
    border: 1px solid #d83d3d;
    background: #d83d3d11;
}

.summary.solo {
    margin-top: 1rem;
    width: calc(100% - 1rem);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
ul li svg {
    position: relative;
    top: 0.15rem;
    margin-right: 0.5rem;
}

.replaceBanner {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem;
    padding-left: 60px;
    text-align: center;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
@keyframes steps-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes checked-blip {
    from {
        background: var(--menu-bg);
    }
    to {
        background: var(--primary-color);
    }
}

@keyframes checked-current-effect {
    0% {
        background: var(--inset-bg);
    }
    50% {
        background: var(--primary-light-color);
    }
    100% {
        background: var(--inset-bg);
    }
}
@keyframes droppedfile-zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
