#settings {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 2rem;
}

.shadow {
    box-shadow: 0 0.2rem 0.5rem var(--item-border-color);
}

#settings .submit {
    display: flex;
    justify-content: flex-end;
}
#settings .button {
    margin: 1rem;
    background: var(--primary-color);
    color: #fff;
    width: 60px;
    text-align: center;
}

#settings .button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

#settings .container {
    background: var(--menu-bg);
    border-radius: 0.5rem;
    width: 50%;
    min-width: 600px;
}

#settings .container h1 {
    margin: 0;
    background: var(--primary-color);
    font-weight: 300;
    color: #fff;
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

#settings .container hr {
    margin: 0;
    border-color: var(--item-border-color);
    border: none;
    border-top: 1px solid var(--item-border-color);
}

#settings .container p {
    font-size: 1rem;
    color: var(--text-color);
    padding: 1rem;
}

#settings .setting-item span {
    font-size: 1rem;
    color: #f00;
    font-weight: 400;
    font-style: italic;
}
#settings .setting-item {
    margin: 2rem 1rem;
}

#settings .setting-item label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

#settings .currency-select {
    width: 300px;
}
