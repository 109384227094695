:root {
    --crop-color-1: var(--primary-color);
    --crop-color-2: #b00;
    --crop-color-3: #0b0;
    --crop-color-4: #08d;
    --header-height: 30px;
    --menu-bg: #fff;
    --menu-bg-opacity: #ffffffaa;
    --menu-text-color: #333;
    --button-text-color: #fff;
    --button-bg: var(--primary-color);
    --input-text-color: #000;
    --input-border-color: #ccc;
    --input-focus-bg: #fff;
    --input-bg: #fff;
    --input-bg-autofill: #fff;
    --input-bg-on-dark: #fff;
    --text-color: #333;
    --item-border-color: #00000022;
    --primary-item-color: #fff;
    --container-bg: #fafafc;
    --sub-container-bg: var(--menu-bg);
    --scrollbar-color: var(--bg-color);
    --codemirror-bg: #ddd;
    --inset-bg: #efeeef;
    --warning-bg: #ff000022;
    --warning-color: #990000;
    --tooltip-border-color: var(--primary-color);
}

[data-theme="darkmode"] {
    --menu-bg: #333338;
    --menu-bg-opacity: #000000;
    --menu-text-color: #ccc;
    --button-text-color: #fff;
    --button-bg: var(--primary-color);
    --input-text-color: #ccc;
    --input-border-color: #1a1a1a;
    --input-focus-bg: #202022;
    --input-bg: #222226;
    --input-bg-autofill: var(--input-bg);
    --input-bg-on-dark: var(--menu-bg);
    --text-color: #ccc;
    --item-border-color: #00000033;
    --primary-item-color: #fff;
    --container-bg: #444449;
    --sub-container-bg: var(--menu-bg);
    --scrollbar-color: var(--bg-color);
    --codemirror-bg: #eee;
    --inset-bg: #252528;
    --warning-bg: #700;
    --warning-color: #e55;
    --tooltip-border-color: var(--primary-color);
}

[data-client="default"] .navbar .navbar-logo {
    filter: none;
}
.navbar .navbar-logo {
    filter: grayscale();
}
html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
}

.button {
    cursor: pointer;
    padding: 0.5rem;
    font-size: 16px;
    box-shadow: 0 0.2rem 0 #00000022;
    border-radius: 0.5rem;
    transition: 0.05s;
}
.button:active {
    transform: translateY(0.2rem);
    box-shadow: 0 0 0 #00000022;
}

body {
    background: var(--dark-background, #272d37);
}
input {
    font-family: "Noto Sans";
}

#login {
    width: 400px;
    min-height: 180px;
    text-align: center;
    margin: 20vh auto;
    display: flex;
    flex-direction: row;
    background: var(--menu-bg);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 1rem 3rem #00000055;
}

#login input[type="text"],
#login input[type="password"] {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    border: 1px solid #00000022;
    box-shadow: 0 0.1rem 0.5rem #00000011;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    background: var(--input-bg);
    color: var(--input-text-color);
    /* backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); */
}

#login input[type="submit"] {
    padding: 0.5rem 1rem;
    border: 0px solid transparent;
    background: var(--primary-color);
    border-radius: 0.5rem;
    color: var(--button-text-color);
    display: inline-block;
    box-shadow: 0 0.2rem 0 #00000022;
    cursor: pointer;
}
#login input[type="submit"]:hover {
    background: var(--primary-light-color);
}
#login label {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: var(--menu-text-color);
}

#login h4 {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    width: 100%;
    margin-top: 20%;
    color: var(--text-color);
}
#main {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-family: "Noto Sans";
}

.login-failed {
    display: block;
    color: #ee2222;
    margin-top: 1rem;
    font-size: 13px;
    text-transform: uppercase;
}

.background {
    height: 100%;
    width: 100%;
    display: block;
    font-family: "Noto Sans";
    background-image: var(--bg-image, "");
    top: 0;
    background-size: cover;
    transition: background-image 0.2s;
    position: absolute;
    z-index: -2;
    transition: opacity 0.2s;
}

.background.hidden {
    opacity: 0;
}
.bold {
    font-weight: 300;
    margin-right: 1rem;
}

.logo {
    position: absolute;
    top: 1rem;
    left: calc(var(--navbar-width) + 1rem);
    max-width: var(--logo-width, 200px);
    height: var(--logo-height, auto);
    display: block;
    z-index: -1;
}

.background-btn,
.darkmode-btn,
.help-btn {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    font-size: 24px;
    background: #ffffff22;
    height: calc(24px + 0.5rem);
    width: calc(24px + 0.5rem);
    text-align: center;
    vertical-align: middle;
    padding: 0px;
    border-radius: 0.5rem;
    line-height: calc(24px + 0.9rem);
    color: #000;
    cursor: pointer;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 10;
}

.darkmode-btn {
    right: 6rem;
}
.background-btn {
    right: 3.5rem;
}

.background-btn:hover,
.darkmode-btn:hover {
    background: var(--primary-color);
    opacity: 0.5;
    color: #fff;
}

span.version {
    position: fixed;
    left: calc(var(--navbar-width) + 0.5rem);
    bottom: 0.5rem;
    color: var(--text-color);
    opacity: 0.7;
    font-size: 12px;
}

/* Tooltip overrides */
#tt-tags.react-tooltip {
    opacity: 1;
}

.react-tooltip {
    color: var(--text-color);
}
div[class*="styles-module_info"] {
    background-color: var(--menu-bg);
    border-radius: 0.5rem;
    color: #00000088;
    /* box-shadow: 0 0.1rem 0.3rem #00000044; */
}

div[role="tooltip"].react-tooltip {
    z-index: 9999999;
    background: var(--menu-bg);
    color: var(--text-color);
    pointer-events: all;
    border: 1px solid var(--tooltip-border-color);
}

div[role="tooltip"].react-tooltip p {
    margin: 0.25rem;
}

div[role="tooltip"].react-tooltip a {
    border-bottom: 1px dotted var(--primary-color);
    text-decoration: none;
    color: var(--primary-color);
}

div[role="tooltip"].react-tooltip__place-top > .react-tooltip-arrow,
div[role="tooltip"].react-tooltip__place-top-start > .react-tooltip-arrow,
div[role="tooltip"].react-tooltip__place-top-end > .react-tooltip-arrow {
    border-bottom: 1px solid var(--tooltip-border-color);
    border-right: 1px solid var(--tooltip-border-color);
}

div[role="tooltip"].react-tooltip__place-right > .react-tooltip-arrow {
    border-bottom: 1px solid var(--tooltip-border-color);
    border-right: 1px solid var(--tooltip-border-color);
}

div[role="tooltip"].react-tooltip__place-bottom > .react-tooltip-arrow,
div[role="tooltip"].react-tooltip__place-bottom-start > .react-tooltip-arrow,
div[role="tooltip"].react-tooltip__place-bottom-end > .react-tooltip-arrow {
    border-right: 1px solid var(--tooltip-border-color);
    border-bottom: 1px solid var(--tooltip-border-color);
}

div[role="tooltip"].react-tooltip__place-left > .react-tooltip-arrow {
    border-bottom: 1px solid var(--tooltip-border-color);
    border-right: 1px solid var(--tooltip-border-color);
}

.react-tooltip.tiny {
    padding: 0.25rem;
    font-size: 12px;
}

@keyframes manageFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
