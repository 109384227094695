.campaign-manager .button {
    display: inline-block;
    background: var(--primary-color);
    color: var(--primary-item-color);
}

.campaign-manager {
    padding-left: 1rem;
    min-height: 400px;
}

.profile-select.button {
    background: var(--primary-item-color);
    color: var(--text-color);
    width: 200px;
    display: block;
}
.profile-select.button svg {
    transform: translateY(0.1rem);
}

.crumb {
    font-size: 13px;
    display: inline-block;
    border: 1px solid var(--item-border-color);
    background: var(--menu-bg);
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
}

.crumb strong {
    font-weight: 600;
}
.crumb svg {
    margin-left: 1rem;
    transform: translateY(0.1rem);
    cursor: pointer;
}

.manage .google-main {
    border: 1px solid #00000011;
    border-radius: 0.5rem;
    background: var(--menu-bg);
    padding: 1rem;
    display: block;
    width: calc(100% - 3rem);
    box-shadow: 0 0.2rem 0 #00000011;
}

.manage .campaign-manager ul {
    padding-right: 0px;
    margin-block-end: 0px;
    width: 100%;
}

.campaign-manager ul li {
    background: var(--menu-bg);
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: 1px solid var(--item-border-color);
    box-shadow: 0 0.1rem 0 #00000011;
    color: var(--text-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    cursor: default;
}
.campaign-manager ul li span {
    line-height: 100%;
    align-content: center;
}

.campaign-manager ul li span .linked {
    color: var(--input-border-color);
    margin-left: 1rem;
}

.campaign-manager ul li .button {
    font-size: 10px;
    padding: 0.5rem;
    margin: 0px;
}

.campaign-manager .processing {
    background: var(--menu-bg);
    opacity: 0.8;
    z-index: 99;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-content: center;
}

.campaign-manager .processing p {
    text-align: center;
    font-size: 24px;
    color: var(--text-color);
}

.campaign-manager p span {
    font-weight: 300;
    font-size: 14px;
}

.campaign-manager p span i {
    font-style: normal;
    font-weight: 500;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}
.campaign-manager p span i:hover {
    color: var(--text-color);
    text-decoration: none;
}

.campaign-manager .open {
    padding: 0.2rem 0.5rem;
    font-size: 13px;
    /* border-radius: 0.2rem; */
}

.campaign-manager .open svg {
    transform: translateY(0.1rem);
}

.campaign-manager .backplate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.campaign-manager .confirm {
    background: var(--menu-bg);
    display: inline-block;
    padding: 1rem;
    border: 1px solid var(--item-border-color);
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0 #00000022, 0 0.5rem 2rem #00000033;
    height: 120px;
    position: relative;
    top: 25%;
}

.campaign-manager .export-all span {
    transform: translateY(-0.1rem);
    display: inline-block;
}
.campaign-manager .export-all svg {
    font-size: 20px;
    transform: translateY(0.1rem);
    margin-right: 0.5rem;
}

.campaign-manager .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
}

.campaign-manager .buttons div.button {
    margin: 0 0.5rem;
    display: inline-block;
    width: 80px;
}

.campaign-manager .buttons div.button.cancel {
    background: var(--inset-bg);
    color: var(--text-color);
}

.campaign-manager [class$="-container"] {
    width: calc(100% - 1rem);
}
