.tools {
    margin-left: var(--navbar-width);
    height: 95dvh;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.tools .container {
    background: var(--menu-bg);
    height: 100%;
    width: calc(80% - var(--navbar-width));
    display: block;
    border-radius: 0.5rem;
    box-shadow: 0 1rem 3rem #00000055;
    overflow: hidden;
}

.tools .container .title {
    background: var(--primary-color);
    padding: 1rem;
    color: #fff;
}

.tools .container .content {
    padding: 1rem;
    background: var(--menu-bg);
}
.tools .icon-buttons {
    border-bottom: 1px solid var(--inset-bg);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.tools .icon-button {
    border: 1px solid var(--inset-bg);
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--container-bg);
}
.tools .icon-button svg {
    font-size: 60px;
    clear: both;
    display: block;
    width: 100%;
    color: var(--input-border-color);
}
.tools .icon-button.active svg {
    color: var(--primary-color);
}
.tools .icon-button span {
    display: block;
    width: 100%;
    text-align: center;
    color: var(--text-color);
    text-transform: uppercase;
    font-size: 10px;
}

/*
    SEARCH
*/
.tools .search-form,
.tools .results-list {
    color: var(--text-color);
    background: var(--container-bg);
    border: 1px solid var(--inset-bg);
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 0.2rem 0 #00000022;
    margin-bottom: 1rem;
}
.tools .search-form select,
.tools .search-form input {
    box-sizing: border-box;
    height: 28px;
    padding: 0.2rem;
    font-size: 14px;
    border-radius: 0.5rem;
    border: 1px solid var(--inset-bg);
    background: var(--menu-bg);
    color: var(--text-color);
    margin: 0 0.5rem;
    transform: translateY(-0.1rem);
}
.tools .search-form label[for="hidden"] {
    font-size: 10px;
    text-transform: uppercase;
}
.tools .search-form #hidden {
    transform: translateY(0.5rem);
    margin: 0 0.5rem 0 0;
}
.tools .search-form #btn {
    border: 0px transparent;
    padding: 0.2rem;
    width: 100px;
    background: var(--primary-color);
    color: #fff;
}

.tools ::-webkit-scrollbar-track {
    background: var(--container-bg);
    padding: 0px;
    margin: 0px;
    border-radius: 0.5rem;
}
.tools ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
    border: 4px solid var(--container-bg);
    border-radius: 10px;
}
.tools .results-list {
    height: 40vh;
    overflow-y: scroll;
    background: var(--container-bg);
}
.tools .results-list table {
    width: 100%;
    border-collapse: collapse;
    font-weight: 300;
}
.tools .results-list thead tr {
    font-weight: 600;
}
.tools .results-list td {
    padding: 0.1rem 0;
}
.tools .results-list tr:hover {
    background: var(--menu-bg);
}
.results-list a:link,
.results-list a:visited {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
}
